import logo from './mlenderlogo.png';
import discordlogo from './discordlogo.png';
import twitterlogo from './twitterlogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Table, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Connection, PublicKey, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import { Program, BN, AnchorProvider, web3 } from '@project-serum/anchor';
import idl from './idl.json';
import { Alert } from 'react-bootstrap';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { useWallet, WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Buffer } from 'buffer';
import { v4 } from 'uuid'
import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import { AccountLayout, ASSOCIATED_TOKEN_PROGRAM_ID,TOKEN_PROGRAM_ID,NATIVE_MINT,createAssociatedTokenAccountInstruction,getAccount,createInitializeMintInstruction,createMint,getOrCreateAssociatedTokenAccount,mintTo,createAssociatedTokenAccount,getAssociatedTokenAddress,transfer,
} from "@solana/spl-token";
window.Buffer = Buffer;
require('@solana/wallet-adapter-react-ui/styles.css');


 
function formatTimeAgo(date) {
  const now = new Date();
  const dayDiff = differenceInDays(now, date);
  const hourDiff = differenceInHours(now, date) % 24;
  const minuteDiff = differenceInMinutes(now, date) % 60;

  let timeAgo = '';
  if (dayDiff > 0) {
    timeAgo += `${dayDiff}D`;
  }
  if (hourDiff > 0 || dayDiff > 0) {  // Gün farkı varsa saat de ekleyin
    timeAgo += `${hourDiff}H`;
  }
  timeAgo += `${minuteDiff}M`;

  return timeAgo;
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>Time's up!</span>;
  } else {
    // Render a countdown
    return (
      <span id="mycontttasd">
        {days}D{hours}H{minutes}M{seconds}S
      </span>
    );
  }
};


const wallets = [ new PhantomWalletAdapter() ]
const { SystemProgram, Keypair } = web3;
const opts = {
  preflightCommitment: "processed"
}

var renew = false;
var mytime;
var highestoffer = 0;
var highestofferx = 0;
var highestoffery = 0;
const systID = new PublicKey("11111111111111111111111111111111");
const programID = new PublicKey("H8XxBbw776ASayZGZD2zNa37CezLngXGa4HdxoXmTrQg");
const CLOCK_PROGRAM_ID = new PublicKey('SysvarC1ock11111111111111111111111111111111');
const prodadd = new PublicKey("F8ZnwyUt8yNEjrMJpv3sphFsEt7Nscwwztz8Uiok1wPn");
const usdcaddress = new web3.PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
);
const rawpoolaccount = new web3.PublicKey(
  "95JZw2xwtCZyVj66t7v2vuQTiaUDxyPsWPBQchPZdjNX"
);
const rawpooltokenaccount = new web3.PublicKey(
  "2mmrCaeFgnWjYEZBgL4an72NezC53htFZuiE61p9to4F"
);
const rawsolanapoolaccount = new web3.PublicKey(
  "2EecfK68CQiTw4VHhLa2mZseos4Z7nRG3CXPmDQRk1KL"
);
var lendamount = 40;
var load = false;
var loadho = false;

function App() {
  const wallet = useWallet()
  const [walletKey, setWalletKey] = useState(null);
  const [buttonText, setButtonText] = useState('Initialize');
  const [functionCompleted, setFunctionCompleted] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [loanlist, setloanlist] = useState([]);
  const [myloanlist, setmyloanlist] = useState([]);
  const [borrowlist, setborrowlist] = useState([]);

  useEffect(() => {
    // Cüzdanın bağlantı durumunu kontrol eder ve değişiklik olduğunda state'i günceller
    setIsWalletConnected(wallet.connected);
  }, [wallet.connected]);

  useEffect(() => {
    if (isWalletConnected) {
      console.log("walletigy1");
      load = false;
      loadLendandBorrows();
    } else {
      highestoffer = 0;
      setborrowlist([]);
      setloanlist([]);
      setmyloanlist([]);
      load = false;
      loadho = false;


      console.log("walletigy2");
      loadho = false;
      getHOfirst();
    }
  }, [isWalletConnected]);

  
  



  //Borrow buttons

  const [buttons, setButtons] = useState([]); // State olarak buttons dizisini tanımlayın

  // Buton bileşenini tanımla
  function MyButton({ id, buttonText }) {
    return (
      <Button className='lendbtn'  variant="outline-success"  type="submit" onClick={() => borrow(id)} id={id}>
        {buttonText}
      </Button>
    );
  }


  //Borrow buttons end



  
  const handleInputChange = (event) => {
    const value = event.target.value;
    // Düzenli ifade ile deseni kontrol et: En fazla üç basamaklı tam sayılar ve isteğe bağlı iki basamaklı ondalık kısım
    if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
      setInputValue(value);
      lendamount = value;
    }
  };

  const handleInputBlur = () => {
    // Kullanıcı input'tan ayrıldığında sayısal değeri kontrol et
    const numericValue = parseFloat(inputValue);
    if (isNaN(numericValue) || numericValue < 10 || numericValue > 200) {
      setInputValue(''); // Değer istenen aralıkta değilse, input'u temizle
    }
  };






  const qetProvider = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
  };

  const connectWallet = async () => {
    const provider = qetProvider();
    if (provider) {
      try {
        const response = await provider.connect();
        const pubKey = await provider.publicKey;
        console.log("userspubkey", response.publicKey.toString());
        setWalletKey(response.publicKey.toString());


        // Kullanıcının cüzdan bağlantısını kestiğinde loglama yap
      provider.on('disconnect', () => {
        console.log('User has disconnected their wallet');
        // Burada gerekli diğer işlemler yapılabilir
        highestoffer = 0;
        setborrowlist([]);
        setloanlist([]);
        setmyloanlist([]);
        load = false;
        loadho = false;
        getHOfirst();

        
      });



      provider.on('accountChanged', (publicKey) => {
        console.log('Hesap değişti:', publicKey.toBase58());

        highestoffer = 0;
        setborrowlist([]);
        setloanlist([]);
        setmyloanlist([]);
        load = false;
        loadho = false;
         getHOfirst();
        //load = false;
        //loadLendandBorrows();
    });


     


        //
      } catch (err) {
        console.log("here failll");
      }
    } else {
      console.log("no provider right now");
    }
  };

  useEffect(() => connectWallet, []);

  


  async function getProvider() {
    /* create the provider and return it to the caller */
    /* network set to local network for now */
    const network = "https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b";
    const connection = new Connection(network, opts.preflightCommitment);

    const provider = new AnchorProvider(
      connection, wallet, opts.preflightCommitment,
    );


    return provider;
  }

 

  async function repay(pkey, lendercc, bowner){
    console.log("Bu lenderacc", lendercc);
    console.log("Borç program adresi", pkey.toString());
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    const pubKey = await provider.publicKey;
    const User_Wallet = provider;



    var getlendfrombrrw = await program.account.lendacc.fetch(lendercc);
    console.log("very important", getlendfrombrrw.owner.toString());

    const associatedreceiverAddress = await getAssociatedTokenAddress(
      usdcaddress,
      getlendfrombrrw.owner
    );

    console.log("this acc will receive money.",associatedreceiverAddress);





    let transaction1b = new web3.Transaction();
    const associatedAddress = await getAssociatedTokenAddress(
      usdcaddress,
      User_Wallet.publicKey
    );
   

    transaction1b.add(
      await program.methods
        .repay()
        .accounts({
          user: User_Wallet.publicKey,
          borrower: new web3.PublicKey(pkey.toString()) ,
          lender: lendercc,
          receiver: rawsolanapoolaccount, //getpools solana account from initializer
          fromAta: associatedAddress, // Pool usdc tool account address
          toAta: associatedreceiverAddress, // alıcının usdc token adresi nedir? //şu an havuza geri atıyor fakat bu direkt lend edenin adresine doğrudan gidecek.
          poolAccount: rawpoolaccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programID,
          royaltyaddr: prodadd,
          clock: new PublicKey("SysvarC1ock11111111111111111111111111111111"),
        })
        .instruction()
    );

    try {
      const txid = await wallet.sendTransaction(transaction1b, provider.connection);
      console.log(txid);
      let mylink = "https://explorer.solana.com/tx/"+txid; 
      toast(({ closeToast }) => <div> Repay successfully <a target="_blank" href={mylink}>check from solana.fm</a></div>);
      checkTransactionFinalized(txid);
    } catch (error) {
      console.log(error);
    }


  }

  async function foreclose(lendercc, owner){
  
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    const pubKey = await provider.publicKey;
    const User_Wallet = provider;
    let transaction1b = new web3.Transaction();
    const associatedAddress = await getAssociatedTokenAddress(
      usdcaddress,
      User_Wallet.publicKey
    );
   

    transaction1b.add(
      await program.methods
        .foreclose()
        .accounts({
          user: User_Wallet.publicKey,
          lender: lendercc,
          receiver: rawsolanapoolaccount, //getpools solana account from initializer
          poolAccount: rawpoolaccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programID,
          lendOwner: owner,
          clock: new PublicKey("SysvarC1ock11111111111111111111111111111111"),
        })
        .instruction()
    );

    try {
      const txid = await wallet.sendTransaction(transaction1b, provider.connection);
      console.log(txid);
      let mylink = "https://explorer.solana.com/tx/"+txid; 
    toast(({ closeToast }) => <div> Successfully foreclosed   <a target="_blank" href={mylink}>check from solana.fm</a></div>);
    checkTransactionFinalized(txid);
    } catch (error) {
      console.log(error);
    }

  }

async function canceloffer(pkey){
  
  console.log("please cancel offer", pkey);
  const provider = await getProvider();
  const program = new Program(idl, programID, provider);
  const pubKey = await provider.publicKey;
  const User_Wallet = provider;

  const associatedAddress = await getAssociatedTokenAddress(
    usdcaddress,
    User_Wallet.publicKey
  );


  //WARNING
  let transaction1 = new web3.Transaction();
      transaction1.add(
        await program.methods
          .withdrawal()
          .accounts({
            from: User_Wallet.publicKey,
            fromAta: rawpooltokenaccount,
            toAta: associatedAddress,
            poolAccount: rawpoolaccount,
            lender : new web3.PublicKey(pkey.toString()),
            tokenProgram: TOKEN_PROGRAM_ID,
          }).instruction()
      );
      

      try {
        const txid = await wallet.sendTransaction(transaction1, provider.connection);
        console.log(txid);
        let mylink = "https://explorer.solana.com/tx/"+txid; 
        toast(({ closeToast }) => <div> Successfully withdrew <a target="_blank" href={mylink}>check from solana.fm</a></div>);
        checkTransactionFinalized(txid);
      } catch (error) {
        console.log(error);
      }
}


async function getHOfirst() {
if(loadho)  {
    console.log("already intialized");
} else {
  highestoffer = 0;
  let newButtons = [];
  const provider = await getProvider();
  const program = new Program(idl, programID, provider);
  try {
    var getlendirectly = await program.account.lendacc.all();
    if(getlendirectly.length >0 ){
      for (let lid = 0; lid < getlendirectly.length; lid++) {
              if(getlendirectly[lid].account.lstatus == false ) {
                if(getlendirectly[lid].account.iscanceled == false) {
                  var amountx = getlendirectly[lid].account.lamount.toNumber();  
                  var formattedAmount = (amountx / 1000000).toFixed(2);
                  let getapyfee = (getlendirectly[lid].account.lamount.toNumber() / 100) * 2;
                  let totalamount = getlendirectly[lid].account.lamount.toNumber() + getapyfee;
                  var formattedAmounty = (totalamount / 1000000).toFixed(2);
                  const buttonId = `${lid}`; // Her butona özgü bir ID
                  const buttonText = `Borrow`; // Her butona özgü bir metin
                  if(amountx > highestoffer){
                    newButtons[0] = <MyButton key={lid} id={buttonId} buttonText={buttonText} />;
                    highestoffer = amountx;
                    highestofferx = formattedAmount;
                    highestoffery = formattedAmounty;
                    setButtons(newButtons);
                  } else {
                    console.log("pass RN.");
                  } 
                }
              } else {
                console.log("This lend have borrower....")
              }
           
          
      }
      loadho = true;
    } else {
      console.log("Don't have an active lend right now!");
    }
  } catch (error) {
    console.error("Hesapları alırken hatax oluştu:", error);
  }
} 
}

async function 
loadLendandBorrows() {
  console.log("loadLendandBorrowsx")
  if(load)  {
      console.log("already intialized");
  } else {
    console.log("loadLendandBorrows");
    highestoffer = 0;
    let newButtons = [];
    
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    const pubKey = await provider.publicKey;
    const User_Wallet = provider;

    //LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- 
    try {

      var getlendirectly = await program.account.lendacc.all();
      console.log("lendaccAccounts",getlendirectly.length);

      if(getlendirectly.length >0 ){

        setloanlist([]);

        console.log("lendaccAccounts",getlendirectly[0].account.owner);
        console.log("asdkjaskjld", getlendirectly);
        for (let lid = 0; lid < getlendirectly.length; lid++) {
          console.log("Logistic", lid);
              if(getlendirectly[lid].account.owner.equals(User_Wallet.publicKey)) {
                console.log("Bu sizin kendi lend adresiniz kendinze borç veremezsiniz...", getlendirectly[lid].account.owner);
                console.log("Bu sizin kendi lend adresiniz kendinze borç veremezsiniz...", User_Wallet.publicKey);
                var amountx = getlendirectly[lid].account.lamount.toNumber();  
                var formattedAmount = (amountx / 1000000).toFixed(2);
                console.log("hep ayni mi",getlendirectly[lid].account.owner.toString());
                const newloan = {
                  bump: getlendirectly[lid].account.bump,
                  amount : formattedAmount,
                  ramount : getlendirectly[lid].account.lamount.toNumber(),
                  lstatus : getlendirectly[lid].account.lstatus,
                  lendtime : getlendirectly[lid].account.lendtime.toNumber(),
                  lendpaidtime : getlendirectly[lid].account.lendpaidtime.toNumber(),
                  owner : getlendirectly[lid].account.owner.toString(),
                  pkey: getlendirectly[lid].publicKey
                }
                setloanlist(oldLoanList => [...oldLoanList, newloan]);
              } else if(getlendirectly[lid].account.owner.equals(systID)){
                console.log("program upgrade edildiği için buraya düşüyor.");
              } else {
                if(getlendirectly[lid].account.lstatus == false ) {

                  if(getlendirectly[lid].account.iscanceled == false) {
                    console.log("Lend details --->",getlendirectly[lid].account.bump);
                    console.log("Lend details --->",getlendirectly[lid].account.lamount.toNumber());
                    console.log("Lend details --->",getlendirectly[lid].account.lstatus);
                    console.log("Lend details --->",getlendirectly[lid].account.lendtime.toNumber());
                    console.log("Lend details --->",getlendirectly[lid].account.lendpaidtime.toNumber());
                    var amountx = getlendirectly[lid].account.lamount.toNumber();  
                    var formattedAmount = (amountx / 1000000).toFixed(2);

                    let getapyfee = (getlendirectly[lid].account.lamount.toNumber() / 100) * 2;
                    let totalamount = getlendirectly[lid].account.lamount.toNumber() + getapyfee;
                    var formattedAmounty = (totalamount / 1000000).toFixed(2);


                    const buttonId = `${lid}`; // Her butona özgü bir ID
                    const buttonText = `Borrow`; // Her butona özgü bir metin
                    if(amountx > highestoffer){
                      console.log("buraya gelir musunx");
                      newButtons[0] = <MyButton key={lid} id={buttonId} buttonText={buttonText} />;
                      highestoffer = amountx;
                      highestofferx = formattedAmount;
                      highestoffery = formattedAmounty;
                      setButtons(newButtons);



                    } else {
                      console.log("pass RN.");
                    }

                    
                  }
                
                


                } else {
                  console.log("This lend have borrowerxx....")
                }
              }
            
        }
        




      } else {
        console.log("Don't have an active lend right now!");
      }

      
    } catch (error) {
      console.error("Hesapları alırken hatax oluştu:", error);
    }
     //LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- LEND -*- 


      //FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*-  FETCH LENDS -*- FETCH LENDS -*- 



      setmyloanlist([]);
     
      var [getcounteracctwo] =  web3.PublicKey.findProgramAddressSync([Buffer.from("lcount"), provider.wallet.publicKey.toBuffer()], programID);
      console.log("getcounteracctwo", getcounteracctwo.toString());
      const accountInfotwo = await program.provider.connection.getAccountInfo(getcounteracctwo);

    if (accountInfotwo !== null) {
      var lcountery = await program.account.lCount.fetch(getcounteracctwo);
      console.log("lcountery",lcountery.lcount);

      for (let lidd = 0; lidd < lcountery.lcount; lidd++) {
        console.log("liddliddliddlidd" , lidd);
        const bufferr = Buffer.alloc(4); // 4 baytlık bir buffer oluşturun
        bufferr.writeUInt32LE(lidd, 0); // 'lid' değerini little-endian formatında buffer'a yazın
        const seedsz = [Buffer.from("lender"),User_Wallet.publicKey.toBuffer(),bufferr];
        const [lenderPDA, bbump] = web3.PublicKey.findProgramAddressSync(
          seedsz,
          programID
        );
        console.log("asdasdsa", lenderPDA.toString());
        console.log(program.account);
        var getlendirectly = await program.account.lendacc.fetch(lenderPDA);
        console.log("asdkjaskjld2x", getlendirectly);

        console.log("lend paid time", getlendirectly.lendpaidtime.toNumber());
         
        let getapyfee = (getlendirectly.lamount.toNumber() / 100) * 2;
        let totalamount = getlendirectly.lamount.toNumber() + getapyfee;
        var formattedAmounty = (totalamount / 1000000).toFixed(2);

         
        var amountx = getlendirectly.lamount.toNumber(); 
        var formattedAmount = (amountx / 1000000).toFixed(2);
        console.log("hep ayni mi",getlendirectly.owner.toString());
        console.log("it can works ayni mi", lenderPDA.toString());
      
        const newlendlist = {
          bump: getlendirectly.bump,
          amount : formattedAmount,
          ramount : getlendirectly.lamount.toNumber(),
          lstatus : getlendirectly.lstatus,
          iscanceled : getlendirectly.iscanceled,
          isforeclosed: getlendirectly.isforeclosed,
          lendtime : getlendirectly.lendtime.toNumber(),
          lendpaidtime : getlendirectly.lendpaidtime.toNumber(),
          owner : getlendirectly.owner.toString(),
          pkey: lenderPDA
        }
        setmyloanlist(oldLoanListx => [...oldLoanListx, newlendlist]);
        
      }
    }
       




      //FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*- FETCH LENDS -*-  FETCH LENDS -*- FETCH LENDS -*- 







    var lid = 0;

    try {
      setborrowlist([]);
     
      var [getcounteracc] =  web3.PublicKey.findProgramAddressSync([Buffer.from("bcount"), provider.wallet.publicKey.toBuffer()], programID);
      console.log("getcounteracc", getcounteracc.toString());
      const accountInfo = await program.provider.connection.getAccountInfo(getcounteracc);

    if (accountInfo !== null) {
      var bcountery = await program.account.bCount.fetch(getcounteracc);
      console.log("bcountery",bcountery.bcount);

      for (let lidd = 0; lidd < bcountery.bcount; lidd++) {
        console.log("liddliddliddlidd" , lidd);
        const bufferr = Buffer.alloc(4); // 4 baytlık bir buffer oluşturun
        bufferr.writeUInt32LE(lidd, 0); // 'lid' değerini little-endian formatında buffer'a yazın
        const seedsz = [Buffer.from("borrower"),User_Wallet.publicKey.toBuffer(),bufferr];
        const [borrowerPDA, bbump] = web3.PublicKey.findProgramAddressSync(
          seedsz,
          programID
        );
        console.log("asdasdsa", borrowerPDA.toString());
        console.log(program.account);
        var getborrowdirectly = await program.account.borrowacc.fetch(borrowerPDA);
        console.log("asdkjaskjld", getborrowdirectly);
         
          let getapyfee = (getborrowdirectly.amount.toNumber() / 100) * 2;
        let totalamount = getborrowdirectly.amount.toNumber() + getapyfee;
        var formattedAmounty = (totalamount / 1000000).toFixed(2);

        const newbloan = {
          bump: getborrowdirectly.bump,
          borrowtime : getborrowdirectly.borrowtime.toNumber(),
          borrowpaidtime : getborrowdirectly.borrowpaidtime.toNumber(),
          lendacc : getborrowdirectly.lendacc.toString(),
          owner : getborrowdirectly.owner.toString(),
          pkey: borrowerPDA,
          amount: formattedAmounty,
       
        }

        setborrowlist(oldLoanListz => [...oldLoanListz, newbloan]);


     

        
        


        
      
        
      }


    }
    
      
    
        
       
      } catch (error) {
          console.error("Hesapları alırken hatay oluştu:", error);
        } 





   
  
  

 


    load = true;
  }

}




async function checkTransactionFinalized(txid) {
  console.log("checkTransactionFinalized");
  const provider = await getProvider();
  const program = new Program(idl, programID, provider);
  let finalized = false;

  while (!finalized) {
    try {
      // İşlem onay durumunu kontrol et
      const response = await program.provider.connection.getSignatureStatuses([txid]);
      const status = response && response.value[0];

      if (status) {
        finalized = status.confirmationStatus === 'finalized';

        if (finalized) {
          console.log("İşlem finalized oldu:", txid);
          load = false;
          loadLendandBorrows();
        } else {
          console.log("İşlem henüz finalized değil, tekrar kontrol ediliyor...");
        }
      }
    } catch (error) {
      console.error("İşlem durumu kontrolü sırasında hata oluştu:", error);
    }

    // Belirli bir süre bekleyin (örneğin 5 saniye)
    await new Promise(resolve => setTimeout(resolve, 5000));
  }
}



 async function borrow(id){
 

  console.log("loadLendandBorrows");
    const provider = await getProvider();
    const program = new Program(idl, programID, provider);
    const pubKey = provider.publicKey;
    const User_Wallet = provider;

    if(isWalletConnected == false) {
      toast(({ closeToast }) => <div> Connect with your wallet first. </div>);
      //window.location.reload(true);
    } else { 

    try {
      var lidd = 0;
      var getlendirectly = await program.account.lendacc.all();

      console.log("lendaccAccounts from borrow", getlendirectly.length);
      console.log("lendaccAccounts from borrow", getlendirectly);

      console.log(typeof id); // Çıktı: 'string'
      console.log("id is hre", id);

      var x = parseInt(id);
      console.log(x);

    

    const [bcountPDA, bcbump] = PublicKey.findProgramAddressSync(
      [Buffer.from("bcount"), User_Wallet.publicKey.toBuffer()],
      programID
    );
    console.log("i need get counter value", bcountPDA.toString());
    // Hesap mevcutsa, bCount bilgisini al

    const accountInfo = await program.provider.connection.getAccountInfo(bcountPDA);
    
    if (accountInfo !== null) {
      console.log("bcountPDA", bcountPDA);
      var bcountery = await program.account.bCount.fetch(bcountPDA);
      lidd = bcountery.bcount;
      console.log("real lidd is here", lidd);
    } else {
      lidd = 0;
      console.log("bcountPDA sifir");
    }
    const bufferr = Buffer.alloc(4); // 4 baytlık bir buffer oluşturun
    bufferr.writeUInt32LE(lidd, 0); // 'lid' değerini little-endian formatında buffer'a yazın
    const seedsz = [Buffer.from("borrower"),User_Wallet.publicKey.toBuffer(),bufferr];
    const [borrowerPDA, bbump] = web3.PublicKey.findProgramAddressSync(
      seedsz,
      programID
    );
    const associatedAddress = await getAssociatedTokenAddress(
      usdcaddress,
      User_Wallet.publicKey
    );
  //BORROW CODES

  let transaction1x = new  web3.Transaction();
  transaction1x.add(
    await program.methods
      .borrow(lidd, bbump)
      .accounts({
        user: User_Wallet.publicKey,
        borrower: borrowerPDA,
        lender: new web3.PublicKey(getlendirectly[x].publicKey.toString()), //Lender account from lendacc
        bcounter: bcountPDA,
        receiver: rawsolanapoolaccount, //getpools solana account from initializer
        fromAta: rawpooltokenaccount, // Pool usdc tool account address
        toAta: associatedAddress, // gondericinin usdc token adresi nedir?
        poolAccount: rawpoolaccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram:  web3.SystemProgram.programId,

        clock: new PublicKey("SysvarC1ock11111111111111111111111111111111"),
      })
      .instruction()
  );

  try {
    const txid = await wallet.sendTransaction(transaction1x, provider.connection);
    console.log(txid);
    let mylink = "https://explorer.solana.com/tx/"+txid; 
    toast(({ closeToast }) => <div> Successfully borrowed <a target="_blank" href={mylink}>check from solana.fm </a></div>);
    checkTransactionFinalized(txid);
  } catch (error) {
    console.log(error);
  }


      //BORROW CODES END
 
 

    } catch (error) {



      console.error("Hesapları alırken hata oluştu:", error);
    }


  }

 }

 async function lend() {
  console.log("lend", inputValue);

  if(isWalletConnected == false) {
    toast(({ closeToast }) => <div> Connect with your wallet first. </div>);
  } else if(inputValue < 2) {
    //let mylink = "https://explorer.solana.com/tx/"+txx; 
    console.log("minimum 2 usdc req");
    toast(({ closeToast }) => <div> Minimum 2 USDC required. </div>);
  } else if(inputValue > 120) {
    //let mylink = "https://explorer.solana.com/tx/"+txx; 
    console.log("Maxium 120 usdc allowed");
    toast(({ closeToast }) => <div> Minimum 2 USDC required. </div>);
  } else {
 
  const provider = await getProvider();
  const program = new Program(idl, programID, provider);
  const pubKey = await provider.publicKey;
  const User_Wallet = provider;
  var lid;
  var [getcounteracc] = await web3.PublicKey.findProgramAddressSync([Buffer.from("lcount"), provider.wallet.publicKey.toBuffer()], programID);
  const accountInfo = await program.provider.connection.getAccountInfo(getcounteracc);
  

    // Hesap mevcutsa, lCount bilgisini al
    if (accountInfo !== null) {
      console.log("myaccjj", getcounteracc);
      console.log(program.account);
      var lcountery = await program.account.lCount.fetch(getcounteracc);
      lid = lcountery.lcount;

    } else {
      lid = 0;
    }
     




    
    const buffer = Buffer.alloc(4); // 4 baytlık bir buffer oluşturun
    buffer.writeUInt32LE(lid, 0); // 'lid' değerini little-endian formatında buffer'a yazın

    const seeds = [Buffer.from("lender"),User_Wallet.publicKey.toBuffer(),buffer];
    const [lenderPDA, lbump] = web3.PublicKey.findProgramAddressSync(
      seeds,
      programID
    );


    const [lcountPDA, _bump] = PublicKey.findProgramAddressSync(
      [Buffer.from("lcount"), User_Wallet.publicKey.toBuffer()],
      programID
    );


    const associatedAddress = await getAssociatedTokenAddress(
      usdcaddress,
      User_Wallet.publicKey
    );

    console.log(
      "Users usdc token account is here!!",
      associatedAddress.toString()
    );


  let transaction1x = new web3.Transaction();
  transaction1x.add(
    await program.methods
      .lend(lid,new BN(lendamount* 10**6), lbump)
      .accounts({
        from: User_Wallet.publicKey,
        lender: lenderPDA,
        lcounter: lcountPDA,
        fromAta: associatedAddress, //kullanıcının token adresi //CH4N
        toAta: rawpooltokenaccount, //havuzun adresi.
        tokenProgram: TOKEN_PROGRAM_ID,
        clock: new PublicKey("SysvarC1ock11111111111111111111111111111111"),
      })
      .instruction()
  );

  try {
    const txid = await wallet.sendTransaction(transaction1x, provider.connection);
    console.log(txid);
    let mylink = "https://explorer.solana.com/tx/"+txid; 
    toast(({ closeToast }) => <div> Successfully lent <a target="_blank" href={mylink}>check from solana.fm</a></div>);
    checkTransactionFinalized(txid);


  } catch (error) {
    console.log(error);
  }



  }

 }


  async function initialize() {
    console.log("init");
    const provider = await getProvider();
        /* create the program interface combining the idl, program ID, and provider */
        const program = new Program(idl, programID, provider);
        const pubKey = await provider.publicKey;
        const User_Wallet = provider;

        
        
    
        //im also need to get this address . 3WSwtLLHKKa7LZi7GgCVTvtJSzpiQvDjfuUbrUe8supT
    
        const associatedAddress = await getAssociatedTokenAddress(
          usdcaddress,
          User_Wallet.publicKey
        );
    
        console.log(
          "Users usdc token account is here!!",
          associatedAddress.toString()
        );
    
     
        const [poolPda, bumptw] = PublicKey.findProgramAddressSync(
          [Buffer.from("pool-token-account")],
          programID
        );

        const [counterPda, bump] = PublicKey.findProgramAddressSync(
          [Buffer.from("token-pool")],
          programID
        );
    
        const [solPDA, solbump] = PublicKey.findProgramAddressSync(
          [Buffer.from("solana-pool")],
          programID
        );
    
        console.log("User_Wallet.publicKey.toString()",User_Wallet.publicKey.toString());
        console.log("programID is here", programID.toString());
        
        console.log("poolPDA", poolPda.toString());


         

        //initalize

        console.log(" associatedAddress",associatedAddress);

        let transaction1 = new web3.Transaction();
        console.log("xsdasd", program.methods.initializePool);
        transaction1.add(
        await program.methods
          .initializePool(bump, bumptw, solbump, new BN(2), new BN(5), new BN(1000), new BN(5))
          .accounts({
            admin: User_Wallet.publicKey,
            poolAccount: counterPda,
            poolTokenAccount: poolPda,
            mint: usdcaddress,
            systemProgram: web3.SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            solacc: solPDA,
            roy: associatedAddress,
            rent: SYSVAR_RENT_PUBKEY,
          })
          
          .instruction()
      );

      try {
        console.log("asdasda", User_Wallet.publicKey );

        const txid = await wallet.sendTransaction(transaction1, provider.connection);
        console.log(txid);
        let mylink = "https://explorer.solana.com/tx/"+txid; 
        toast(({ closeToast }) => <div> Initilized <a target="_blank" href={mylink}>check from solana.fm </a></div>);
      } catch (error) {
        console.log(error);
      }

      //initilize end






  }






  return (
    <div className="App">

 


<Navbar  id="nbb">
        <Container >
          <Navbar.Brand href="#home">
            <img
              alt=""
              id="loqo"
              src={logo}
              width="158"
              height="50"
              className="d-inline-block align-top"
            />{' '}
           
          </Navbar.Brand>
          <div className="ms-auto">
          <div className='wallet-button'> <WalletMultiButton /> </div>
           
          </div>


          
        </Container>
      </Navbar>
    
      <header className="App-header">

      


        

      <hr></hr>
      <hr></hr>
      <h6 className="myh7">Offer USDC loans for 1 Solana as collateral,<br></br> allowing borrowers to obtain USDC.</h6>
      <hr></hr>
      


      <div className="containerx">
         
        


      
       <Tabs defaultActiveKey="borrow"  className="mb-3">
        <Tab eventKey="borrow" title="Borrow">
        <div>
        

          
          <div className="containerx" >
          <h6 className="sttext">Highest Offer :  <span className='whitex'> {highestofferx} $</span>  </h6>
          <h6 className="mysubhel">You will payback  {highestoffery}$</h6>
          <br></br><br></br>


          <h6 className="myh6">BORROW USDC FOR 1 SOLANA</h6>
          <h6 className="mysubh">payback in 7 days</h6>


            {isWalletConnected ?
            buttons
            :
            <div className='wallet-button wbtn'> <WalletMultiButton /> </div>
            }

            
          </div>
        <br></br>

          


    <div className="containerx">
     
     <h6 className="myh6">MY LOANS</h6>
 
 
     <Table striped  hover size="lg" variant="transparent" className='transparent-table' >
    <thead>
      <tr>
      <th>Amount</th>
        <th>Status</th>
        
        
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {borrowlist.map((item) => (
        <tr key={v4()}>

          <td>{item.amount} $</td>

          
          <td> 

          { Date.now() - (item.borrowtime * 1000)  > 604800 * 1000 ? (
                   
                   <a target="_blank" href={`https://explorer.solana.com/address/${item.pkey}`}>
            {formatTimeAgo(new Date(item.borrowtime * 1000))}
            </a> 
               ) : item.borrowpaidtime === 0 ? (
                <Countdown  date={(item.borrowtime * 1000) + 604800 * 1000 } renderer={renderer} />
               ) : (
                <a target="_blank" href={`https://explorer.solana.com/address/${item.pkey}`}>
                {formatTimeAgo(new Date(item.borrowtime * 1000))}
                </a> 
               )
               }

               
            
            
          

            
            
            
           </td>
          
        
          <td>
            { Date.now() - (item.borrowtime * 1000)  > 604800 * 1000 ? (
                   
                <span>FORECLOSED</span>
            ) : item.borrowpaidtime === 0 ? (
              <Button className='lendbtn' variant="outline-success" onClick={() => repay(item.pkey, item.lendacc, item.owner)}>
                Repay 
              </Button>
            ) : (
              <span>PAID</span>
            )
            }
          </td>
        </tr>
      ))}
    </tbody>
  </Table>




     </div>



  






        </div>
      </Tab>




      <Tab  eventKey="lending" title="Lend">
        <div>
          

          <div className="containerx">
         <h6 className="myh6">Earn %2 APR weekly..</h6>
    
          <Form.Control className='custom-input' type="text" placeholder="Enter usdc amount" value={inputValue} onChange={handleInputChange} />
          <h6 className="mysubh">Highest Offer :    {highestofferx} $   </h6>

            <br></br>
      
          <h6 className="myh6">LEND USDC FOR 1 SOLANA</h6>
      
       {isWalletConnected ?
        <Button className='lendbtn' variant="outline-success"  type="submit" onClick={lend}> Lend   </Button>
        :
        <div   className='wallet-button wbtn'> <WalletMultiButton /> </div>
      
      }
        


    </div>

            <br></br>

    <div className="containerx" id="offerscont">
     <h6 className="myh6">All your offers.</h6>

     <Table striped  hover className='transparent-table'>
    <thead>
      <tr>
      <th>Amount</th>
        <th>Status</th>
        <th> </th>
      </tr>
    </thead>
    <tbody>
      {myloanlist.map((item) => (
        <tr key={v4()}>
          <td>{item.amount} $</td>

          <td>


          {item.lstatus == true && Date.now() - (item.lendtime * 1000)  > 604800 * 1000 ? (
            <span> FORECLOSED   </span>

          ) : item.lendpaidtime > 0  ? (
            <span> PAID   </span>
          ) : item.lstatus === false && item.iscanceled == false && item.lendpaidtime > 0  ? (
            <span> SEEKING   </span>
          ) : item.lstatus === false && item.iscanceled == false && item.lendpaidtime === 0  ? (
            <span> SEEKING   </span>
          ) : item.lstatus === true && item.iscanceled == false && item.lendpaidtime === 0  ? (
            <span> ACCEPTED   </span>
          ) 
          
          
          
          : item.iscanceled === true ? (
            <span> CANCELED   </span>
          ) : (
              <span> SEEKING   </span>

            )}


            
            
            </td>
          
          
          <td>

          {item.isforeclosed == true ?
            <span> CLAIMED   </span>
          : item.lstatus == true && Date.now() - (item.lendtime * 1000)  > 604800 * 1000 ? (
              <Button size="sm" className='lendbtn' variant="outline-success" onClick={() => foreclose(item.pkey, item.owner)}>
              CLAIM
            </Button>
          ) : item.lstatus === false && item.iscanceled === false && item.lendpaidtime === 0 ? (
              <Button size="sm" className='lendbtn' variant="outline-success" onClick={() => canceloffer(item.pkey)}>
                Cancel
              </Button>
            ): item.lstatus === true && item.iscanceled === false && item.lendpaidtime === 0  ? ( 
            
              <a target="_blank" href={`https://explorer.solana.com/address/${item.pkey}`}>
              {formatTimeAgo(new Date(item.lendtime * 1000))} </a>
            
            ) : item.lstatus === false && item.iscanceled === false ? ( 
            <a target="_blank" href={`https://explorer.solana.com/address/${item.pkey}`}>
              {formatTimeAgo(new Date(item.lendtime * 1000))} </a> 

             ) : ( 
              
              
             <a target="_blank" href={`https://explorer.solana.com/address/${item.pkey}`}>
              {formatTimeAgo(new Date(item.lendtime * 1000))} </a> 

              
            )}
         
            
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
     
     
     
     </div>
   
    
           


 



        </div>
      </Tab>
    </Tabs>

              </div>


   


  
      
   
        
        
      </header>


      <footer className="footer mt-auto py-3" id="mf">
        <Container>
          <Row>
            <Col className="text-white text-center semi-bold-text">
            <span id="ftxt" >  DECENTRALIZED LENDING PLATFORM v2.0.7 </span> <br></br>
            <span id="ftxtw" >  @2023 All Right Reserved. <a href="https://twitter.com/ManagementLabs"> @ManagementLabs </a></span>
              <br></br>

            <span id="ftxtw" > 
            <a  href="https://twitter.com/ManagementLabs"> <img width="30px;"  src={twitterlogo}></img>  </a>
            <a href="https://discord.gg/Qg8wX9xJA4"> <img width="30px;" src={discordlogo}></img>  </a>

              {/*  <Button variant="outline-secondary" className="subbtn" onClick={initialize}>{buttonText}</Button> */}     
             
            
           
             
              </span>
            </Col>
          </Row>
        </Container>
      </footer>



      <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick={false}
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  theme="dark"
                  />

             




    </div>
  );

  
}

const AppWithProvider = () => (
  <ConnectionProvider endpoint="https://mainnet.helius-rpc.com/?api-key=dcb866ca-03d5-416f-8528-5bcd9784a67b">
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
)

export default AppWithProvider;
